<template>
  <div>
    <table class="tbl_row">
      <colgroup>
        <col width="140">
        <col>
        <col width="140">
        <col>
        <col width="140">
        <col>
      </colgroup>
      <tbody>
        <tr>
          <th rowspan="2">실화주1</th>
          <td>
            <e-auto-complete-cst-enm @change="change1" :ctr-cd="ctrCd" :cd-nm="actCstEnm" />
          </td>
          <th rowspan="2">실화주2</th>
          <td>
            <e-auto-complete-cst-enm @change="change2" :ctr-cd="ctrCd" :cd-nm="test2.actCstEnm" />
          </td>
          <th rowspan="2">readonly</th>
          <td>
            <e-auto-complete-cst-enm @change="change2" :ctr-cd="ctrCd" :cd-nm="test2.actCstEnm" :read-only="true" />
          </td>
        </tr>
        <tr>
          <td>
            {{ actCstEnm }} ({{ actCstCd }})
          </td>
          <td>
            {{ test2.actCstEnm }} ({{ test2.actCstCd }})
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import EAutoCompleteCstEnm from '@/components/common/EAutoCompleteCstEnmV2'

export default {
  name: 'SampleAutoCompleteCstEnm',
  components: {
    EAutoCompleteCstEnm
  },
  data: function () {
    return {
      ctrCd: 'KR',
      actCstEnm: '',
      actCstCd: '',
      test2: {
        actCstEnm: 'ACTIVATE DA',
        actCstCd: ''
      }
    }
  },
  methods: {
    change1 (vo) {
      this.actCstEnm = vo.cdNm
      this.actCstCd = vo.cd
    },
    change2 (vo) {
      this.test2.actCstEnm = vo.cdNm
      this.test2.actCstCd = vo.cd
    }
  }
}

</script>
